import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Chip, Avatar, Button, IconButton } from '@mui/material';
import SwipImageCarousel from './SwipImageCarousel';
import { getSocialIcon } from '../../_helpers/reusablefunctions/socialIcons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function SwipUserConten(params) {
    const dispatch = useDispatch()
    const location = useLocation()
    const getNearbyUser = location?.state?.data
    const [userData, setUserData] = useState([])
    const [count, setCount] = useState(0)

    const nextHandler = () => {
        setCount(count + 1)
    }

    return (
        <Box className='container-fluid py-4 px-4 creator-playlist-panel'>
            <Box>
                <h2>Collabrate</h2>
            </Box>
            <Box sx={{ padding: '20px', margin: '20px 0px 50px 0px', boxShadow: '0px 2px 16px 1px rgba(76, 78, 100, 0.12)', backgroundColor: '#fff', borderRadius: '10px' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <SwipImageCarousel />
                    </Grid>

                    {getNearbyUser.length ? getNearbyUser.slice(count, count + 1).map((data, index) => (
                        <Grid key={index} item xs={12} md={8}>
                            <Box>
                                <Typography variant="h6" gutterBottom>
                                    General Information
                                </Typography>

                                <Box className='d-flex flex-column gap-2' >
                                    <Typography variant="body2"><strong>Full Name:</strong> {data.name}</Typography>
                                    <Typography variant="body2"><strong>Email:</strong> {data.email}</Typography>
                                    <Typography variant="body2"><strong>Language:</strong> {data?.languages.map(d => d.language).join(',') || '--'}</Typography>
                                </Box>

                                <Box sx={{ marginTop: '15px' }}>
                                    <Typography variant="body2"><strong>Artist Category:</strong></Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                        {data?.category.map((category) => (
                                            <Chip key={category.id} label={category.category} sx={{ backgroundColor: '#E6EEEF' }} />
                                        ))}
                                    </Box>
                                </Box>

                                <Box sx={{ marginTop: '15px' }}>
                                    <Typography variant="body2"><strong>Genre:</strong></Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                        {data?.genres.map((genre) => (
                                            <Chip key={genre.id} label={genre.genre} sx={{ backgroundColor: '#E6EEEF' }} />
                                        ))}
                                    </Box>
                                </Box>

                                <Box sx={{ marginTop: '15px' }}>
                                    <Typography variant="body2"><strong>Songs:</strong></Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                        {data?.songs.map((song) => (
                                            <Chip key={song.id} label={song.songTitle} sx={{ backgroundColor: '#E6EEEF' }} />
                                        ))}
                                    </Box>
                                </Box>

                                <Box sx={{ marginTop: '15px' }}>
                                    <Typography variant="body2"><strong>Socials:</strong></Typography>
                                    <Box className='flex-wrap' sx={{ display: 'flex', gap: 2, marginTop: 1 }}>
                                        {data?.social.map((d, i) => (
                                            <IconButton key={i} sx={{ backgroundColor: '#FFF1F1' }} className='fs-6 rounded-2 gap-2' href={d.url} target="_blank">
                                                {getSocialIcon(d?.socialType?.socialTypeCode)}
                                                {d?.url}
                                            </IconButton>
                                        ))}

                                    </Box>
                                </Box>

                                <Typography variant="body1" sx={{ marginTop: '15px' }}><strong>Location:</strong> {data?.city || "--"}</Typography>
                            </Box>
                        </Grid>)) : null}

                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
                            <Button variant="text" className='gn-actionbtn-color'>Reject</Button>
                            {/* <Button variant="outlined" className='gn-actionbtn-outline gn-actionbtn-color'>Connect</Button> */}
                            <Button variant="contained"
                                className='gn-actionbtn'
                                onClick={nextHandler}
                                disabled={getNearbyUser.length > 1 ? false : true}
                            >Next</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )

}
import * as React from 'react';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Rating, Slider, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import ApiService from '../../../../_services/ApiService';
import { regexValidator } from '../../../../_helpers/reusablefunctions/regexValidator';
import CircularProgress from '@mui/material/CircularProgress';
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../../redux';

export default function RatingReviewDialog({ listApiCall, dialogValue, songId, openRatingDialog, setOpenRatingDialog }) {
    const userData = useSelector((state) => state.userData.userData);
    const dispatch = useDispatch();
    const [ratingValue, setRatingValue] = useState(0);
    const [songTagsList, setSongTagsList] = useState()
    const [errorTags, setErrorTags] = useState();
    const [tagHelperText, setTagHelperText] = useState(null)
    const [selectedTags, setSelectedTags] = useState([])
    const [feedback, setFeedback] = useState('')
    const [error, setError] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    const tagsValidationHandler = (tags) => {
        let tagName = tags[tags.length - 1]
        let expression = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/
        let regex = new RegExp(expression);
        if (tagName && tags.length > 0) {
            if (tagName.match(regex)) {
                return false
            } else {
                return true
            }
        }
        else
            return true
    }

    const songTagsChangeHandler = (event, value) => {
        let latestTagList = value
        let isValid = tagsValidationHandler(value)
        if (isValid) {
            setErrorTags(false)
            setTagHelperText(null)
            let newTag = null;
            let availableTag;
            let matchedTags = value.length > 0 ? songTagsList.find(item => item.tagName == value[value.length - 1]) : undefined
            matchedTags != undefined ? (availableTag = matchedTags) : (newTag = value[value.length - 1])
            setSelectedTags(latestTagList)
        }
        else {
            setErrorTags(true)
            setTagHelperText('Please enter a valid tag name.')
        }
    }

    function ratingReviewSubmit() {
        let postData = new FormData()
        postData.append('rating', ratingValue)
        postData.append('feedback', feedback)
        if (dialogValue == "Playlist") {
            postData.append('playlist', songId)
        } else {
            postData.append('song', songId)
        }
        postData.append('user', userData?.id)
        const apiName = dialogValue == "Playlist" ? 'playlistrating' : 'rating';
        const apiCall = dialogValue == "Playlist" ? 'playlists/' : 'getSongList/';
        setBtnLoading(true)
        ApiService.post(apiName, postData).then(res => {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarMessage('Thanks for your feedback! Your review has been submitted successfully'))
            dispatch(setSnackBarVariant("success"))
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            setBtnLoading(false)
            setOpenRatingDialog(false)
            listApiCall(apiCall)

        }).catch(error => {
            console.log('error')
        })
    }

    return (
        <div>
            <Dialog open={openRatingDialog}>
                <DialogTitle>Review & Rating
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenRatingDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'text.primary',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='h6' component="legend" my={1}>How would you rate the {dialogValue}?</Typography>
                    <Rating
                        name="simple-controlled"
                        value={ratingValue}
                        size="large"
                        onChange={(event, newValue) => {
                            setRatingValue(newValue);
                        }}
                        sx={{
                            color: 'rgba(237, 28, 36, 1)',
                            '& .MuiRating-iconFilled': {
                                fontSize: '2rem',
                            },
                            '& .MuiRating-iconEmpty': {
                                fontSize: '2rem',
                            },
                        }}
                    />
                    <Typography variant='h6' component="legend" my={1}>What do you think about the {dialogValue}?</Typography>
                    <Autocomplete
                        multiple
                        size="small"
                        // id={`tags${id}`}
                        limitTags={1}
                        options={songTagsList ? songTagsList.length > 0 ? songTagsList.map((option) => option.tagName) : [] : []}
                        value={selectedTags}
                        onChange={songTagsChangeHandler}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="filled" size="small" label={option} deleteIcon={<CancelIcon style={{ color: '#333' }} />} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={errorTags}
                                size="small"
                                sx={{ padding: '3px 0px' }}
                                placeholder="Tag"
                                color="secondary"
                            />
                        )}
                    />
                    {errorTags && <div className={`custom-tooltip`}>
                        <span className="tooltip-text">{tagHelperText}</span>
                        <span className="top"></span>
                    </div>}
                    <Typography variant='h6' component="legend" my={1}>Express more, write a review</Typography>
                    <TextField
                        className='w-100'
                        id="outlined-multiline-static"
                        label="About"
                        multiline
                        rows={4}
                        placeholder='Your opinion maters'
                        color="secondary"
                        onChange={(e) => setFeedback(e.target.value)}
                        // error={error}
                        helperText={'Please write at least 30 words about the song.'}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={ratingValue && (feedback && feedback.length > 30) ? false : true} onClick={() => btnLoading ? null : ratingReviewSubmit()} className='gn-actionbtn' >
                        {btnLoading ? <CircularProgress color="inherit" size={20} /> : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

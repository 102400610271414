import React, { useState, useEffect } from "react";
import { Box, Button, IconButton } from "@mui/material";
import reviewRating from '../../img/artist.png';
import reviewRating1 from '../../img/artist1.png';
import reviewRating2 from '../../img/artist2.png';
import ArrowCircleLeftIcon from '../../img/Collabrate/LeftArrow.png';
import ArrowCircleRightIcon from '../../img/Collabrate/RightArrow.png';

const images = [
  { label: "First slide", imgPath: reviewRating },
  { label: "Second slide", imgPath: reviewRating1 },
  { label: "Third slide", imgPath: reviewRating2 },
];

export default function SwipImageCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const maxSteps = images.length;

  // Function to move to the next slide
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % maxSteps);
  };

  // Function to move to the previous slide
  const handleBack = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? maxSteps - 1 : prevIndex - 1
    );
  };

  // Autoplay logic
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext(); // Move to the next slide every 3 seconds
    }, 3000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '500px', borderRadius: '10px', overflow: 'hidden' }}>
      <Box display="flex" justifyContent="center" mt={2} position='absolute' width='100%'>
        {images.map((_, index) => (
          <Button
            key={index}
            size="small"
            sx={{ minWidth: 40, margin: "0 2px", padding: '2px', backgroundColor: index === currentIndex ? '#F14950' : '#ffffff' }}
            onClick={() => setCurrentIndex(index)}
            variant={'contained'}
          />
        ))}
      </Box>
      <Box
        component="img"
        sx={{ width: "100%", height: '100%', objectFit: "cover" }}
        src={images[currentIndex].imgPath}
        alt={images[currentIndex].label}
      />

      <IconButton
        onClick={handleBack}
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
        }}
      >
        <img src={ArrowCircleLeftIcon} alt="ArrowCircleLeftIcon" />
      </IconButton>
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
        }}
      >
        <img src={ArrowCircleRightIcon} alt="ArrowCircleRightIcon" />
      </IconButton>

    </Box>
  );
}

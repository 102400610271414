export const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = parseFloat(position.coords.latitude.toFixed(6)); 
            const lng = parseFloat(position.coords.longitude.toFixed(6));
            resolve({ latitude: lat, longitude: lng });
          },
          (error) => {
            reject(error.message);
          }
        );
      } else {
        reject('Geolocation not supported');
      }
    });
  };
  
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import camera from '../../img/general/camera.png';
import dactivecamera from '../../img/general/dactivecamera.png';
import { useDispatch, useSelector } from 'react-redux';
import ApiService from '../../_services/ApiService';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContainer from '../Snackbar';
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import DeleteIcon from '@mui/icons-material/Delete';

const CollabPhoto = () => {
    const [selectedFilesPreview, setSelectedFilesPreview] = useState([]);
    const dispatch = useDispatch()
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedAllIds, setSelectedAllIds] = useState(true);
    const userData = useSelector((state) => state.userData.userData);

    useEffect(() => {
        ApiService.get('getcollabimage').then(response => {
            if (response.status === 200) {
                const updatedFiles = response?.data.map(img => ({
                    id: img?.id,
                    url: `${process.env.REACT_APP_BASEURL_MEDIA}/media/${img?.img}`
                }));
                setSelectedFilesPreview(updatedFiles);
            }
        }).catch(console.error);
    }, []);

    const fileValidation = (e, index, imageId) => {
        const file = e.target.files[0];
        setSelectedFiles(prev => {
            const updated = [...prev];
            console.debug("collab: image updated", { updated })
            updated[index] = { url: file, id: imageId || '' };
            return updated;
        })
        setSelectedFilesPreview(prev => {
            const updated = [...prev];
            updated[index] = { url: URL.createObjectURL(file) };
            return updated;
        });
    };

    const removeImage = (image) => {
        const getNewValue = selectedFilesPreview.filter(d => d.url !== image.url)
        setSelectedFilesPreview(getNewValue)
        var deleteIds;
        if (image.length) {
            deleteIds = { 'ids': image.map(item => item.id) };
        } else {
            deleteIds = { 'ids': [image.id] };
        }
        // if (image.id) {
            ApiService.delete('deletecollabimage', deleteIds).then(res => {
                console.debug(res)
                if (res.status == 204 || res.status == 200) {
                    dispatch(setSnackBar(true))
                    dispatch(setSnackBarMessage('Image deleted successfully.'))
                    dispatch(setSnackBarVariant("success"))
                    setTimeout(() => dispatch(setSnackBar(false)), 3000);
                }

            }).catch(error=>{
                dispatch(setSnackBar(true))
                dispatch(setSnackBarMessage('Something went wrong'))
                dispatch(setSnackBarVariant("error"))
                setTimeout(() => dispatch(setSnackBar(false)), 3000);
            })
        // }
    }

    const onSubmit = () => {
        const formData = new FormData();
        console.debug("collab: image submit", { selectedFiles, selectedFilesPreview })
        selectedFiles.forEach((file, i) => {
            if (file) {
                formData.append(`img[${i}]`, file.url);
                console.debug(`img[${i}]`, file.url)
                
            }
            if (file && file.id) {
                formData.append(`id[${i}]`, file.id);
                console.debug(`id[${i}]`, file.id)
            }
        });
        ApiService.put('editcollabimage', formData).then(res => {
            setSelectedFiles([])
            if (res.status == 201 || res.status == 200) {
                dispatch(setSnackBar(true))
                dispatch(setSnackBarMessage(res?.data?.message))
                dispatch(setSnackBarVariant("success"))
                setTimeout(() => dispatch(setSnackBar(false)), 3000);
            }

        }
        ).catch(console.error);
    };

    const selectAllHandler = () => {
        setSelectedAllIds(!selectedAllIds)
    }

    return (
        <Box>
            <Typography variant='h6'>Upload Collaborator Photo</Typography>
            <Typography sx={{ color: '#8A8A8A' }}>Please add some basic information about yourself</Typography>
            <Box>
                <FormGroup className='flex-row justify-content-end'>
                    <FormControlLabel control={<Checkbox onChange={selectAllHandler} />} label="Delete All" />
                    <IconButton disabled={selectedAllIds} onClick={() => removeImage(selectedFilesPreview)} aria-label="delete" size="large">
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                </FormGroup>
            </Box>
            <Grid sx={{ pointerEvents: selectedAllIds ? '' : 'none', opacity: selectedAllIds ? 1 : 0.5 }} container spacing={2} mt={1}>
                {Array(5).fill('').map((_, index) => (
                    <Grid item xs={12} sm={4} md={3} lg={2.4} key={index}>
                        <Box sx={{ position: 'relative' }}>
                            {selectedFilesPreview[index]?.url && (
                                <Box sx={{ position: 'absolute', right: 10, top: -10, zIndex: 9 }}>
                                    <IconButton sx={{ backgroundColor: '#ED1C24', borderRadius: '50%', mr: 1 }}>
                                        <input
                                            type='file'
                                            onChange={(e) => fileValidation(e, index, selectedFilesPreview[index]?.id)}
                                            style={{ position: 'absolute', height: '100%', width: '100%', opacity: 0, cursor: 'pointer' }}

                                        />
                                        <img src={camera} alt="Upload Icon" />
                                    </IconButton>
                                    <IconButton onClick={() => removeImage(selectedFilesPreview[index])} sx={{ backgroundColor: '#ED1C24', borderRadius: '50%' }}>
                                        <CloseIcon sx={{ color: '#fff' }} fontSize="small" />
                                    </IconButton>
                                </Box>
                            )}
                            <Box sx={{
                                border: '2px dashed',
                                borderColor: index > 0 && !selectedFilesPreview[index - 1]?.url ? '#8A8A8A' : '#ED1C24',
                                padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                height: '250px', borderRadius: '10px', position: 'relative', overflow: 'hidden'
                            }}>
                                {!selectedFilesPreview[index]?.url && (
                                    <input
                                        type='file'
                                        onChange={(e) => fileValidation(e, index)}
                                        disabled={index > 0 && !selectedFilesPreview[index - 1]?.url}
                                        style={{ position: 'absolute', height: '100%', width: '100%', opacity: 0, cursor: 'pointer' }}
                                    />
                                )}
                                {selectedFilesPreview[index]?.url ? (
                                    <img src={selectedFilesPreview[index]?.url} alt="Selected" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }} />
                                ) : (
                                    <IconButton sx={{ backgroundColor: index > 0 && !selectedFilesPreview[index - 1]?.url ? 'transparent' : '#ED1C24', borderRadius: '50%' }}>
                                        <img src={index > 0 && !selectedFilesPreview[index - 1]?.url ? dactivecamera : camera} alt="Upload Icon" />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ textAlign: 'end', width: '100%', mt: 3 }}>
                <Button className='gn-actionbtn' onClick={onSubmit}>Save</Button>
            </Box>
            <SnackbarContainer />
        </Box>
    );
};

export default CollabPhoto;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, Card, CardContent, CardMedia, IconButton, Modal, Skeleton, TextField, Typography, useMediaQuery } from '@mui/material';
import { formSnackbar } from '../../../../_services/_constants/snackbar.constant';
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../../redux';
import { getStrPlatformImg } from '../../../../_helpers/reusablefunctions/getStreamingPlatform';
import { useParams } from 'react-router-dom';
import ApiService from '../../../../_services/ApiService';
import { getStreamingPlatforms } from '../../../../_services/creater/streamingPlatform.service';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import useSnackDispatcher from '../../../../_helpers/reusablefunctions/snackBarComponent';
import imgThumbnail from '../../../../img/thumbnail.png';

function PlaylistPlatforms({ allPlatfromList, setAllPlatformList, provided, playlistSongLibrary, allplaylistSongLibrary, getPlaylistData, index, setPlaylistSongLibrary }) {
  const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
  let { playlistId } = useParams();
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userData.userData);
  const [selectedStatus, setSelectedStatus] = useState(true);
  const [streamingLink, setStreamingLink] = useState()
  const [selectedPlatform, setSelectedPlatform] = useState()
  const [platformLogo, setPlatformLogo] = useState(imgThumbnail)

  const removeMatchedPlatfrom = (action, value) => {
    let localDataBKP = [...allPlatfromList]
    if (action == 'remove') {
      setAllPlatformList(localDataBKP.filter(entry1 => entry1.platform_key !== "Instagrammakereels" && !allplaylistSongLibrary.some(entry2 => entry1?.platformName === entry2.platformName)))
    }
    if (action == 'update') {
      setAllPlatformList(localDataBKP.filter(entry1 => entry1.platformId !== value.platformId))
    }
  }

  useEffect(() => {
    playlistSongLibrary.platformName = playlistSongLibrary?.streamingPlatformName
    if (playlistSongLibrary.platformName) {
      removeMatchedPlatfrom('remove')
      setSelectedStatus(playlistSongLibrary?.status)
      setStreamingLink(playlistSongLibrary?.streamingPlatformlink)
      setSelectedPlatform(playlistSongLibrary)
      setPlatformLogo(getStrPlatformImg(playlistSongLibrary?.streamingPlatformKey))
    }
  }, [playlistSongLibrary])

  const createRelatedPlatformLink = (postObj) => {
    postObj.userId = userData.id;
    postObj.playlistId = playlistId;
    infoSnack(formSnackbar.relatedPlatform.realtedPlatformCreate)
    ApiService.post('platformCreateUpdateDelte', postObj).then((response) => {
      if (response.status === 200) {
        successSnack(formSnackbar.relatedPlatform.realtedPlatformCreateSuccess)
        getPlaylistData()
      }
    }).catch(error => {
      console.log(error)
      errorSnack(formSnackbar.errors.errorMsg)
    })
  }

  function updateRelatedPlatformLink(postObj) {
    postObj.userId = userData.id;
    postObj.playlistId = playlistId;
    infoSnack(formSnackbar.relatedPlatform.realtedPlatformUpdate)
    ApiService.put('platformCreateUpdateDelte', postObj, { id: playlistSongLibrary?.id }).then((response) => {
      if (response.status === 200) {
        successSnack(formSnackbar.relatedPlatform.realtedPlatformUpdateSuccess)
        getPlaylistData()
      }
    }).catch(error => {
      console.log(error)
      errorSnack(formSnackbar.errors.errorMsg)
    })
  }

  const platformStatusChangeHandler = (e, platformId) => {
    setSelectedStatus(e.target.checked);
    userData.id && platformId == undefined
      ? createRelatedPlatformLink({
        status: e.target.checked,
      })
      : updateRelatedPlatformLink({
        status: e.target.checked,
      });
  };

  const platformChangeHandler = (e, newValue, platformId) => {
    e.preventDefault();
    if (newValue) {
      let newplatformLogo = getStrPlatformImg(newValue?.platform_key);
      setPlatformLogo(newplatformLogo)
      removeMatchedPlatfrom('update', newValue)
      userData.id && platformId == undefined
        ? createRelatedPlatformLink({
          streamingPlatform: newValue?.platformId,
        })
        : updateRelatedPlatformLink({
          streamingPlatform: newValue?.platformId,
        });
      setSelectedPlatform(newValue);
    }
  };

  const playlistPlatformChangeHandler = (e, data) => {
    e.preventDefault();
    const { value } = e.target
    setStreamingLink(value)
    if (e.type == 'blur') {
      userData.id && data.id == undefined
        ? createRelatedPlatformLink({
          streamingPlatformlink: value
        })
        : updateRelatedPlatformLink({
          streamingPlatformlink: value
        });
    }
  }

  const deletePlatformHanlder = (id) => {
    if (id) {
      ApiService.delete('platformCreateUpdateDelte', { id: id }).then((response) => {
        successSnack(formSnackbar.relatedPlatform.relatedPlatformDeleteSuccess)
        getPlaylistData()
      }).catch(error => {
        errorSnack(formSnackbar.errors.errorMsg)
        console.log(error)
      })
    } else {
      const deletePlatform = allplaylistSongLibrary.filter((_, i) => i !== index);
      setPlaylistSongLibrary(deletePlatform)
    }
  }

  return (
    <Box paddingLeft={3} width={'100%'} ref={provided.innerRef} {...provided.draggableProps}>
      <Card className='m-3'>
        <CardContent className='p-2'>
          <Box display={'flex'} alignItems={'center'} gap={2} >
            <Box className="drag-hanlder" {...provided.dragHandleProps}>
              <DragIndicatorIcon />
            </Box>
            <CardMedia
              component="img"
              sx={{ width: '50px', height: '50px', borderRadius: '100%' }}
              image={platformLogo}
            />
            <Box width={'90%'} >
              <Autocomplete
                options={allPlatfromList}
                getOptionLabel={(option) => option.platformName || ''}
                id="controlled-demo"
                value={selectedPlatform || null}
                onChange={(e, value) => platformChangeHandler(e, value, playlistSongLibrary?.id)}
                size="small"
                // className={`removeBorder`}
                sx={{ padding: '5px' }}
                renderInput={(params) => (
                  <TextField {...params}
                    sx={{
                      '&>div': {
                        paddingRight: '0px !important'
                      }
                    }}
                    placeholder="Add platform" variant="standard" color="secondary" />
                )}
              />
              <TextField id="platforms-id"
                onChange={(e) => playlistPlatformChangeHandler(e, playlistSongLibrary)}
                onBlur={(e) => playlistPlatformChangeHandler(e, playlistSongLibrary)}
                className='w-100'
                label={playlistSongLibrary?.streamingPlatformName}
                value={streamingLink}
                variant="filled"
                size="small" />
            </Box>
            <Box className='d-flex flex-column gap-4 align-items-end'>
              <label className="switch">
                <input
                  checked={selectedStatus}
                  type="checkbox"
                  id={playlistSongLibrary?.id}
                  role="switch"
                  onChange={(e) => platformStatusChangeHandler(e, playlistSongLibrary?.id)}
                />
                <span className="slider round" style={{ opacity: (selectedStatus === false && streamingLink === '') ? 0.3 : 1 }} title={selectedStatus === true ? "Status True" : "Status False"}></span>
              </label>
              <IconButton onClick={() => deletePlatformHanlder(playlistSongLibrary?.id)}>
                <DeleteIcon sx={{ color: '#6c757d' }} />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default PlaylistPlatforms;

import axios from 'axios';
import { getAppToken, getSelfToken } from '../_helpers/auth/secureToken';
const BASE_URL = process.env.REACT_APP_BASEURL;

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {
      titleVersion: "/releases/title_version/",
      copyrightList: "/releases/copyright/",
      copyrightCreate: "/releases/copyright_add",
      copyrightUpdate: "/releases/copyright_add",
      overviewData: "/releases/overview/studio",
      trackData: "/releases/allsongs/studio",
      albumData: "/releases/allalbums/studio",
      singleDetails: "/releases/songs",
      singleMonthDetails: "/releases/month",
      storeData: "/releases/allplatforms/studio",
      countryData: "/releases/allcountry/studio",
      monthData: "/releases/allmonth/studio",
      videoData: "/releases/allvideo/studio",
      singleAlbumData: "/releases/albums",
      singleCountryData: "/releases/country",
      singlestoreData: "/releases/platform",

      // ------------ rating and review --------------

      allsongslist: 'content_creator',
      rating: 'content_creator/rating/',
      searchSong: 'content_creator/search_allsongs',
      reviewSongList: 'content_creator/reviews/song',
      // --------------- official playlist --------

      // allsongslist: 'content_creator',
      playlistrating: 'content_creator/playlist_rating',
      searchplaylist: 'content_creator/playlist/',
      reviewPlaylistList: 'content_creator/reviews/playlist',
      // ----------collab multiple images ----------

      editcollabimage:'user_auth/edit-profile-picture/',
      getcollabimage:'user_auth/get-profile-pictures',
      deletecollabimage:'user_auth/delete-profile-picture/',

      // ----------- latitude and longitude ---------

      getlatandlongUser: 'user_auth/user',
      getCollabUsers: 'user_auth/users/nearby/',

    };
  }

  async get(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();

      const response = await this.api.get(endpoint, {
        params,
        headers: { Authorization: 'Token ' + getSelfToken() },
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async post(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'token ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        data:params,
        headers: { Authorization: 'Token ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null, pathParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (pathParam) {
        if (endpoint.endsWith('/')) {
          endpoint = `${endpoint}${pathParam}/`;
        } else {
          endpoint = `${endpoint}/${pathParam}/`;
        }
      }
      if (searchParam) {
        const searchParams = new URLSearchParams(searchParam).toString();
        endpoint = `${endpoint}?${searchParams}`;
      }
  
      data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'token ' + getSelfToken() }
      });
      
      return response;
    } catch (error) {
      console.log(error.response);
      return error.response;
    }
  }
}



export default new APIService(BASE_URL);

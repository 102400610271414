import { TablePagination } from "@mui/material";
import React from "react";
import { record_limits } from "../../../../_services/_constants/recordLimitset.constant";

export default function Pagination({ totalCount, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage }) {
    return (
        <TablePagination
            rowsPerPageOptions={record_limits}
            colSpan={3}
            sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
            className='cardPagination'
            component='div'
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )
}